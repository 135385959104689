<div
  class="bg-container"
  [ngStyle]="{ 'background-image': !videoBackground && backgroundImage && 'url(&quot;' + backgroundImage + '&quot;)' }"
>
  <video *ngIf="!!videoBackground" playsinline autoplay [muted]="'muted'" loop class="video-bg"
    [poster]="backgroundImage">
    <source [src]="videoBackground" type="video/mp4" />
  </video>
  <div class="container fashion-login">
    <div class="login-container">
      <ng-container *ngTemplateOutlet="login"></ng-container>
    </div>
  </div>
</div>

<ng-template #login>
  <div class="login-logo">
    <img class="d-none" [src]="logoUrl || './assets/logo.png'" />
  </div>
  <h5 class="text-primary">
    <strong translate>RESET_PASSWORD</strong>
  </h5>
  <form [formGroup]="resetPasswordForm" class="mt-3" (ngSubmit)="onSubmit()">
    <lib-gung-password-input
      gungTranslate="PASSWORD"
      [placeholder]="'PASSWORD' | translate"
      formControlName="password"
      (validPassword)="isValid($event)"
      extraInputClass="{{
        resetPasswordForm.controls.password.invalid || !!resetPasswordForm.errors ? 'custom-login-input is-invalid' : 'custom-login-input'
      }}"
      [passwordRequirement]="passwordRequirements"
    ></lib-gung-password-input>
    <br>
    <lib-gung-password-input
      gungTranslate="PASSWORD_CONFIRM"
      [placeholder]="'PASSWORD_CONFIRM' | translate"
      formControlName="passwordConfirm"
      extraInputClass="{{
        resetPasswordForm.controls.password.invalid || !!resetPasswordForm.errors ? 'custom-login-input is-invalid' : 'custom-login-input'
      }}"
    ></lib-gung-password-input>
    <div class="row mt-4">
      <div class="col-6">
        <!-- /login -->
        <a [routerLink]="['login']" translate>
          <button type="submit" class="btn btn-link text-white" translate>
            BACK_TO_LOGIN
          </button>
          </a>
      </div>
      <div class="col-6 d-flex">
        <button type="submit" class="btn btn-primary ml-auto" [disabled]="resetPasswordForm.disabled" translate>
          SET_PASSWORD
        </button>
      </div>
    </div>
    <div class="alert alert-success" *ngIf="passwordSet" translate>PASSWORD_HAS_BEEN_UPDATED</div>
    <div
      class="alert alert-danger"
      *ngIf="resetPasswordForm.errors && resetPasswordForm.errors.errorSettingPw"
      translate
    >
      ERROR_SET_PASSWORD
    </div>
  </form>
</ng-template>
