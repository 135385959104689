import { environment as defaultEnv } from './default-environment';

export const environment = {
  ...defaultEnv,
  production: false,
  backendUri: 'https://hl-display-1210-api-test.gung.io/',
  allowedPriceLists: [],
  hideMobile: false,
  discountCustomerFinancialGroup: {},
  bolag: '1210',
  sales: true,
  analyticsId: '',
  sentryDsn: '',
  quoteConfirmationEmailAddress: 'support@gung.se',
  orderOurReference: 'GUNG',
  externalOrderType: 8,
  mainCountry: 'fi',
  mainCustomer: '',
  stripeApiKey: undefined,
  availability: {
    useConfigTransform: true,
    bufferDays: 1,
    showTransportTime: false
  },
  getArticlesOutsidePriceLists: true,
  showFavourites: true,
  showApprovalQuote: true,
  showCreateSampleOrder: true
};
