import { Injectable } from '@angular/core';
import {
  Order,
  BackendRerouteService,
  SelectedCustomerService,
  CustomerService,
  OrderToBasketService,
  Customer,
  OrderChangeCustomerConfirmationModalComponent
} from 'gung-standard';
import { Observable, forkJoin, of } from 'rxjs';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { first, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { ActionButton, ActionButtonConfigService, DownloadMultipleFilesComponent } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class JeevesOrderActionConfigService implements ActionButtonConfigService<Order> {
  base = [
    { title: 'XLSX', action: (item: Order) => this.backendRerouteService.reroute('download/order-excel/' + item.id) },
    { title: 'PDF', action: (item: Order) => this.backendRerouteService.reroute('download/order-pdf/' + item.id) },
    { title: 'IMAGES', action: (item: Order) => this.openImageZipDownloadModal(item) },
    { title: 'COPY_TO_CART', action: (item: Order) => this.addOrderToCart(item) }
  ];

  extra = [
    {
      title: 'SHOW_ORDER',
      action: (item: Order) => {
        this.router.navigate(['/orders/', item.id]);
      }
    }
  ];

  modalRef: NgbModalRef;

  constructor(
    protected backendRerouteService: BackendRerouteService,
    protected selectedCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected orderToBasketService: OrderToBasketService,
    protected modalService: NgbModal,
    protected router: Router
  ) {}

  getButtons(item: Order): Observable<ActionButton<Order>[]> {
    // IF order details then do not show the go to order details button
    // Otherwise show the go to order details button
    // e.g: path of order details like /orders/orderId
    if (!!this.router.url && this.router.url.indexOf('/orders/') < 0) {
      return new Observable<ActionButton<Order>[]>(subscriber => {
        subscriber.next([...this.base, ...this.extra]);
        subscriber.complete();
      });
    } else {
      return new Observable<ActionButton<Order>[]>(subscriber => {
        subscriber.next(this.base);
        subscriber.complete();
      });
    }
  }

  addOrderToCart(item: Order) {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        switchMap(selectedCustomer => {
          return forkJoin({
            selectedCustomer: of(selectedCustomer),
            orderCustomer: this.customerService.getCustomer(item.deliveryCustomerId).pipe(first())
          });
        })
      )
      .subscribe(({ selectedCustomer, orderCustomer }) => {
        if (selectedCustomer.id === orderCustomer.id) {
          this.copyToCart(item);
        } else {
          this.changeCustomerDialog(selectedCustomer, orderCustomer, item);
        }
      });
  }

  changeCustomerDialog(currentCustomer: Customer, orderCustomer: Customer, item: Order) {
    this.modalRef = this.modalService.open(OrderChangeCustomerConfirmationModalComponent);
    this.modalRef.componentInstance.currentCustomer = currentCustomer;
    this.modalRef.componentInstance.nextCustomer = orderCustomer;

    this.modalRef.result
      .then(
        resolve => {
          if (resolve === 'Change') {
            this.selectedCustomerService.selectCustomer(orderCustomer.id);
          }
        },
        () => {
          throw new Error('Rejected');
        }
      )
      .then(() => this.copyToCart(item))
      .catch(error => console.log(error));
  }

  copyToCart(item: Order) {
    this.orderToBasketService.addOrderToBasket(item.id).then(
      result => {},
      reason => {}
    );
  }

  openImageZipDownloadModal(item: Order) {
    const option: NgbModalOptions = {
      keyboard: true,
      centered: true,
    };
    
    const ref = this.modalService.open(DownloadMultipleFilesComponent, option);
    const componentInstance = ref.componentInstance as DownloadMultipleFilesComponent;
    componentInstance.orderId = item.id;
  }
}
