<div class="edi-invoice" *ngIf="invoiceData">
  <div class="invoice-header">
    <div class="header-title">
      <h3>
        <span translate="INVOICE"></span>: {{ invoiceData.id }}
        <span style="float: right"><lib-edi-send-status [message]="invoiceData"></lib-edi-send-status></span>
      </h3>
    </div>
    <div class="row">
      <div class="col">
        <lib-action-btn class="float-right mb-2" [(item)]="invoiceData" [config]="actionConfig"></lib-action-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="id" translate>INVOICE_NUMBER</label>
              <input
                class="form-control"
                name="id"
                type="text"
                [(ngModel)]="invoiceData.id"
                [disabled]="configService.disable.invoiceNumber"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="invoice-date" translate>INVOICE_DATE</label>
              <input
                class="form-control"
                name="invoice-date"
                type="text"
                [(ngModel)]="invoiceData.documentReferences.invoice.date"
                [disabled]="configService.disable.invoiceDate"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="currency" translate>CURRENCY</label>
              <input
                class="form-control"
                name="currency"
                type="text"
                [(ngModel)]="invoiceData.currency"
                [disabled]="configService.disable.currency"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="suppler-order-n" translate>SUPPLIER_ORDER_NUMBER</label>
              <input
                class="form-control"
                name="suppler-order-n"
                type="text"
                [(ngModel)]="invoiceData.documentReferences.supplierOrder.id"
                [disabled]="configService.disable.valueDate"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="buyer-order-n" translate>BUYER_ORDER_NUMBER</label>
              <input
                class="form-control"
                name="buyer-order-n"
                type="text"
                [(ngModel)]="invoiceData.documentReferences.buyerOrder.id"
                [disabled]="configService.disable.buyerOrderNumber"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="buyer-order-date" translate>BUYER_ORDER_DATE</label>
              <input
                class="form-control"
                name="buyer-order-date"
                type="text"
                [(ngModel)]="invoiceData.documentReferences.buyerOrder.date"
                [disabled]="configService.disable.buyerOrderDate"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="dispatchadvice"><span translate>DESPATCH_ADVICE_ID</span></label>
              <input
                class="form-control"
                name="dispatchadvice"
                type="text"
                [(ngModel)]="invoiceData.documentReferences.despatchAdvice.id"
                [disabled]="configService.disable.despatchAdviceNumber"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="dispatchadvice"><span translate>DESPATCH_ADVICE_DATE</span></label>
              <input
                class="form-control"
                name="dispatchadvice"
                type="text"
                [(ngModel)]="invoiceData.documentReferences.despatchAdvice.date"
                [disabled]="configService.disable.despatchAdviceNumber"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="delivery-date" translate>DELIVERY_DATE</label>
              <input
                class="form-control"
                name="delivery-date"
                type="text"
                [(ngModel)]="invoiceData.deliveryDate"
                [disabled]="configService.disable.deliveryDate"
              />
            </div>
          </div>

          <div class="col-md-4" *ngIf="!!invoiceData.timestamp">
            <div class="form-group">
              <label class="control-label" for="delivery-date" translate>TIMESTAMP</label>
              <input
                class="form-control"
                name="timestamp"
                type="text"
                [(ngModel)]="invoiceData.timestamp"
                [disabled]="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <lib-edi-gln-number-tabs [ediMessage]="invoiceData"></lib-edi-gln-number-tabs>
      </div>
    </div>
  </div>
  <lib-edi-detail-error-display [ediMessage]="invoiceData"></lib-edi-detail-error-display>
  <div class="invoice-itens">
    <div class="header-title" translate>INVOICE_ROWS</div>
    <lib-edi-row-table [data]="invoiceData.items" [columns]="itemsColumns"></lib-edi-row-table>
  </div>
  <div class="invoice-total">
    <div class="header-title" translate>TOTAL</div>
    <div class="row">
      <div class="col-3" *ngIf="!!invoiceData.summary?.totalVATBaseAmount">
        <div class="form-group">
          <label class="control-label" for="taxable-amount" translate>TAXABLE_AMOUNT</label>
          <input
            class="form-control"
            name="taxable-amount"
            type="text"
            [(ngModel)]="invoiceData.summary?.totalVATBaseAmount.value"
            [disabled]="configService.disable.taxableAmount"
          />
        </div>
      </div>
      <div class="col-3" *ngIf="!!invoiceData.summary?.totalVATAmount">
        <div class="form-group">
          <label class="control-label" for="total-tax-amount" translate>TOTAL_TAX_AMOUNT</label>
          <input
            class="form-control"
            name="total-tax-amount"
            type="text"
            [(ngModel)]="invoiceData.summary?.totalVATAmount.value"
            [disabled]="configService.disable.totalTaxAmount"
          />
        </div>
      </div>
      <div class="col-3" *ngIf="!!invoiceData.summary?.totalRoundingAmount">
        <div class="form-group">
          <label class="control-label" for="rounding">{{ 'ROUNDING' | translate }}</label>
          <input
            class="form-control"
            name="rounding"
            type="text"
            [(ngModel)]="invoiceData.summary?.totalRoundingAmount.value"
            [disabled]="configService.disable.rounding"
          />
        </div>
      </div>
      <div class="col-3" *ngIf="!!invoiceData.summary?.totalPayableAmount">
        <div class="form-group">
          <label class="control-label" for="net-amount" translate="NET_AMOUNT"></label>
          <input
            class="form-control"
            name="net-amount"
            type="text"
            [(ngModel)]="invoiceData.summary?.totalPayableAmount.value"
            [disabled]="configService.disable.netAmout"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="invoice-errors">
    <div class="header-title" translate>ERROR</div>
    <lib-edi-error-messages [errors]="[]"></lib-edi-error-messages>
  </div>
</div>
